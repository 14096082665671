import React from "react";
import * as styles from "./style.module.scss";
import List from './list';
import StyledButton from "../../../atoms/StyledButton";

export default function index() {
  return (
    <div className={styles.content}>
      <div className={styles.desc}>
        <h2>Range of topics</h2>
        <p>
          <span>Our presentation portal covers a range of topics</span> we have collected
          from partners, clients and others. See the front page for selected
          presentations, we can align with you on where to focus.
        </p>
      </div>
      <div className={styles.contentlist}>
        <List text='MASTER WiZR essentials' />
        <List text='MASTER WiZR series on: what, why, who, and how' />
        <List text='Design insights' />
      </div>
      <div className={styles.contentlist}>
        <List text='MASTER WIZR content planner' />
        <List text='Transformative Communication focus' />
        <List text='Practical Video Debrief Alternatives' />
      </div>
      <div className={styles.contentlist}>
        <List text='Big bid insights' />
        <List text='Presentation insights' />
        <List text='Organisation tools' />
        <List text='Video client examples' />
      </div>
      <div className={styles.buttons}>
          {/* <StyledButton title="Visit presentation portal"
          link="https://beta-studio.mwstream.com/control-panel/cover-pages"
          email={true}
           icon extraClass="orangeGray" />
          <StyledButton title="Visit quick guide"
          link="https://beta-studio.mwstream.com/control-panel/cover-pages"
          email={true}
           icon extraClass="borderbtn" /> */}
      </div>
    </div>
  );
}
