import { Link } from "gatsby";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import img1 from "../../../../images/master.png";
// import img2 from "../../../../images/45.1.jpg";
// import img3 from "../../../../images/screen-portal.jpg";
import img1 from '../../../../images/45.1.jpg'
import img2 from '../../../../images/master.png'
import img3 from '../../../../images/33.1.jpg'
import img4 from "../../../../images/graphics.jpg";
import img5 from "../../../../images/45.1.jpg";
import img6 from "../../../../images/graphics.jpg";
import * as styles from "./style.module.scss";
import { responsiveSlider } from "../../../../constants/responsiveSlider";

export default function index() {
  return (
    <div className={styles.content}>
      <div className={styles.images}>
        <img src={img1} />
        <div className={styles.wrapper}>
          <img src={img2}/>
          <h1>Custom Projects</h1>
        </div>
        <img src={img3}/>
      </div>
        <p> We work with your material to organize and present with Show Rooms and Microsites to make a difference in your communications.</p>

      {/* <div className={styles.desc}> */}
        {/* <div>
          <h2>Recommended Presentations</h2>
        </div> */}

        {/* <div className={styles.all}>
          <div className={styles.description}>
          <span>Custom Projects</span>
          <p className={styles.paragraph}>
          We work with your material to organize and present with Show Rooms and Microsites to make a difference in your communications.
          </p>
          <img className={styles.mainImg}  src={img1}/>

          </div>
          <div className={styles.images}>
        <img src={img3} className={styles.secondImg}/>
            
        <img src={img5} className={styles.secondImg}/>
      

          </div>
          </div> */}
          {/* <Link to="#">Read more {">"}</Link> */}
        {/* </div> */}
        
      {/* <div className={styles.contentImg}> */}
        {/* <Carousel responsive={responsiveSlider}> */}
          {/* <Link to='https://stream.masterwizr.com/sharing/encrypted/KXVQW11AO1Q91QPS5HC' className={styles.mainImg}><img className={styles.bottom} src={img1} /></Link> 
          <Link to='https://stream.masterwizr.com/sharing/encrypted/SAIRKYODK6M1Z5NWSEMU95'><img className={styles.secondImg} src={img3} /></Link>
    </div> */}
          {/* <Link to='https://stream.masterwizr.com/sharing/encrypted/ROVNNIPTUSDZ4I94WNPQ5G'><img src={img4} /></Link>
          <Link to='https://stream.masterwizr.com/sharing/encrypted/MMAMEGHUM5S00DREI0CNF'><img src={img5} /></Link>
        <Link to='https://stream.masterwizr.com/sharing/encrypted/BSUY2IVSD14DL3BPLNXV9J'><img src={img6} /></Link> */}
        {/* </Carousel> */}
        {/* <Link to='https://stream.masterwizr.com/sharing/encrypted/AV407FTLC5WHFXQHT6OYB'><img src={img3} /></Link>*/}
      </div> 
  );
}
