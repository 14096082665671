import React, { useEffect, useState } from "react";
import { Image } from "../../../../hooks/gatsbyImg";
import * as styles from "./style.module.scss";
import SwipeableViews from "react-swipeable-views";
import Pagination from "../../../atoms/Pagination";
import img1 from "../../../../images/screen.png";
import img2 from "../../../../images/guy mw.png";
import img3 from "../../../../images/women-for-portal.png";

const Services = () => {
  const [width, setWidth] = useState(0);
  const [index, setIndex] = useState(0);
  const handleChangeIndex = (index) => {
    setIndex(index);
  };
  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  const mobileView = () => {
    return (
      <div className={styles.mobileContainer}>
        <SwipeableViews index={index} onChangeIndex={handleChangeIndex}>
          <img src={img1} />
          <img src={img2} />
          <img src={img3} />
        </SwipeableViews>
        <Pagination dots={3} index={index} />
      </div>
    );
  };

  const desktopView = () => {
    return (
      <div className={styles.desktopContainer}>
        <img src={img1} />
        <img src={img2} />
        <img src={img3} />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <span>Portal</span>
      <h1>Dare to be different. Stand out.</h1>
      <h2>
      Taking inspirations from fashion, media and the best of technology we bring new ways to  presenting what matters to you.
      </h2>
      <div className={styles.icon}>
        <Image filename="smallicon.png" />
      </div>
      { desktopView()}
    </div>
  );
};

export default Services;
