import React from "react";
import * as style from './style.module.scss';
import Card from '../../../atoms/question/index';
import {questions} from '../../../../constants/questions'

export default function index() {
  return (
    <div className={style.content}>
      <div className={style.desc}>
        <h3>Information at your fingertips</h3>
        <p>
          <span>The equivalent of music streaming – easily access and pick what to
          consume right now!</span> <br></br> Instead of customizing material for each new
          meeting the presentation portal provides content for the next 100+
          meetings.
        </p>
      </div>
      <div className={style.questions}>
            {
                questions.map(res =>{
                    return(
                        <Card question={res.question} answer={res.answer} />
                    )
                })
            }
      </div>
    </div>
  );
}
