import React from 'react';
import * as styles from './style.module.scss'

export default function list({text='Design insights'}) {
    return (
        <div className={styles.list}>
            <div className={styles.circle}>
            </div>
            <p>{text}</p>
        </div>
    )
}
