import React ,{useEffect} from "react";
import Footer from "../components/atoms/footer";
import Header from "../components/atoms/header";
import FreeTrial from "../components/atoms/freeTrial";
import Fingertips from '../components/molecules/presentationPortalPage/fingertips';
import Recommended from '../components/molecules/presentationPortalPage/recommendedPresentations';
import Portal from '../components/molecules/presentationPortalPage/portal';
import RangeTopics from '../components/molecules/presentationPortalPage/rangeTopics/index';
import AOS from "aos";
import HeroBanner from "../components/atoms/HeroBanner";
import Head from "../components/ogranisms/head";
import "aos/dist/aos.css";
import StyledButton from '../components/atoms/StyledButton';
export default function Presentationportal() {
  useEffect(() => {
    AOS.init({
      disable: 'mobile',
      duration : 1500
    });
  }, []);
  return (
    <>
    <Head
        title="Presentation Portal"
        description="Information at your fingertips
        The equivalent of music streaming – easily access and pick what to consume right now!
        Instead of customizing material for each new meeting the presentation portal provides content for the next 100+ meetings."
        ogTitle="Presentation Portal"
      />
    <main>
      <Header />
      <HeroBanner filename='portal4.webp' extraClass='presentationBanner'>
                <h3 data-aos="zoom-in" data-aos-delay="100">Our dialogue tool</h3>
                <h1 data-aos="zoom-in" data-aos-delay="200">Presentation Portal</h1>
                <div data-aos="flip-down" data-aos-delay="100">
                {/* <StyledButton title="Open Summary" 
                extraClass="whiteBtn"
                icon
                link="https://beta-studio.mwstream.com/control-panel/cover-pages"
                email={true}
                /> */}
                </div>
            </HeroBanner>
      <Fingertips />
      <Recommended />
      <Portal />
      <RangeTopics />
      <FreeTrial />
      <Footer />
    </main>
    </>
  );
}
