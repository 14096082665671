import React from 'react'
import * as styles from './styles.module.scss';
export default function index({question='What', answer='A collection of dozens of curated online mini presentations.'}) {
    return (
        <div className={styles.cardContent}>
            <h1>{question}</h1>
            <p>{answer}</p>
        </div>
    )
}
