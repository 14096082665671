export const questions = [
    {
        question:'What',
        answer:'A collection of dozens of curated online mini presentations.'
    },
    {
        question:'Why',
        answer:'To improve absorption and retention applying Deep Visual Connection.'
    },
    {
        question:'How',
        answer:'Search feature makes it easy to find any file or specific slide you need'
    }
]